// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-tag-template-js": () => import("/Users/mayur.patel/Documents/workspace/trial/app-trial/blog/src/templates/TagTemplate.js" /* webpackChunkName: "component---src-templates-tag-template-js" */),
  "component---src-templates-post-template-js": () => import("/Users/mayur.patel/Documents/workspace/trial/app-trial/blog/src/templates/PostTemplate.js" /* webpackChunkName: "component---src-templates-post-template-js" */),
  "component---src-templates-page-template-js": () => import("/Users/mayur.patel/Documents/workspace/trial/app-trial/blog/src/templates/PageTemplate.js" /* webpackChunkName: "component---src-templates-page-template-js" */),
  "component---src-templates-index-js": () => import("/Users/mayur.patel/Documents/workspace/trial/app-trial/blog/src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-pages-404-js": () => import("/Users/mayur.patel/Documents/workspace/trial/app-trial/blog/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("/Users/mayur.patel/Documents/workspace/trial/app-trial/blog/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-follow-js": () => import("/Users/mayur.patel/Documents/workspace/trial/app-trial/blog/src/pages/follow.js" /* webpackChunkName: "component---src-pages-follow-js" */),
  "component---src-pages-search-js": () => import("/Users/mayur.patel/Documents/workspace/trial/app-trial/blog/src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-tags-js": () => import("/Users/mayur.patel/Documents/workspace/trial/app-trial/blog/src/pages/tags.js" /* webpackChunkName: "component---src-pages-tags-js" */)
}

